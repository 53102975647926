<template>
  <img
    style="margin-top: 20px"
    width="100"
    height="100"
    src="../assets/logo.png"
  />
  <h2 style="color: #bdff06;">娱聘渠道商平台</h2>
  <InputLine
    style="margin-top: 30px"
    title="账号"
    placeholder="请输入手机号"
    @input="inputUsername"
  />
  <VerifyCodeInputLine
    style="margin-top: 30px"
    :mobile="username"
    title="验证码"
    placeholder="请输入验证码"
    :url="verifyCodeUrl"
    @input="inputVerifyCode"
  />
  <div style="display: flex; flex: 1">
    <button
      :disabled="disabled"
      @click="login"
      :class="[disabled ? 'btn-disabled' : 'btn-enabled']"
    >
      登录
    </button>
  </div>
</template>

<script>
import axios from "axios";
import InputLine from "../components/InputLine.vue";
import VerifyCodeInputLine from "../components/VerifyCodeInputLine.vue";
export default {
  name: "LoginView",
  components: {
    InputLine,
    VerifyCodeInputLine,
  },
  data() {
    return {
      username: "",
      code: "",
    };
  },
  methods: {
    inputVerifyCode(value) {
      this.code = value;
    },
    inputUsername(value) {
      this.username = value;
    },
    login() {
      axios
        .request({
          method: "POST",
          url: process.env.VUE_APP_BASE_URL + "/login",
          params: {
            username: this.username,
            password: this.code,
          },
        })
        .then((response) => {
          if (response.data.code == 0) {
            localStorage.userToken = response.data.data.id
            this.goToIndex();
          } else {
            alert("登录失败： " + response.data.msg);
          }
        })
        .catch(function (error) {
          // 请求失败处理
          alert(error);
        });
    },
    goToIndex() {
      this.$router.push("/index");
    },
  },
  computed: {
    verifyCodeUrl() {
      return (
        process.env.VUE_APP_BASE_URL + `/user/send-login-code/${this.username}`
      );
    },
    disabled() {
      return this.username.trim() == "" || this.code.trim() == "";
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.btn-enabled {
  margin-top: 20px;
  background-color: #bdff06;
  padding: 10px 0;
  max-height: 45px;
  border-radius: 6px;
  width: 100%;
  margin-left: 40px;
  margin-right: 40px;
}

.btn-disabled {
  margin-top: 20px;
  background-color: #444444;
  padding: 10px 0;
  max-height: 45px;
  border-radius: 6px;
  color: #999999;
  width: 100%;
  margin-left: 40px;
  margin-right: 40px;
}
</style>
