<template>
  <div class="header">
    <div class="back-btn">
      <img
        @click="goToWithdraw"
        src="../assets/left-arrow.png"
        width="20"
        height="20"
        style="position: relative; top: 3px"
      />
    </div>
    <div class="title">提现记录</div>
  </div>
  <div class="list-panel" @scroll="handleScroll">
    <div class="item" v-for="(item, index) in result.list" :key="index">
      <div>
        <div v-if="item.paymentType.name === 'AliPay'">
          <img
            src="../assets/alipay.png"
            width="20"
            height="20"
            style="position: relative; top: 5px"
          />支付宝提现
        </div>
        <div v-if="item.paymentType.name === 'Wechat'">
          <img
            src="../assets/wechat.png"
            width="20"
            height="20"
            style="position: relative; top: 5px"
          />微信提现
        </div>
        <div style="position: absolute; right: 15px; top: 15px">
          <div v-if="item.status.name === 'Processing'" style="color: #ffffff">
            +{{ item.amount }}
          </div>
          <div v-if="item.status.name === 'Passed'" style="color: orange">
            +{{ item.amount }}
          </div>
          <div v-if="item.status.name === 'Failed'" style="color: #bdff06">
            +{{ item.amount }}
          </div>
        </div>
      </div>
      <div style="margin: 5px 0">账户余额: ￥{{ item.afterPaidBalance }}</div>
      <div>
        {{ item.createTime }}
      </div>
      <div class="audit-status">
        <div v-if="item.status.name === 'Processing'">审核中</div>
        <div v-if="item.status.name === 'Passed'" style="color: orange">
          提现成功
        </div>
        <div v-if="item.status.name === 'Failed'" style="color: #bdff06">
          提现失败
        </div>
      </div>
      <div v-if="item.status.name === 'Failed'" style="margin-top: 5px; color: #bdff06">
        失败原因: {{ item.reason }}
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "WithdrawJournalView",
  components: {},
  data() {
    return {
      result: {
        list: [],
        total: 0,
      },
      num: 1,
      size: 20,
    };
  },
  mounted() {
    this.loadJournal();
  },
  methods: {
    loadJournal() {
      axios
        .post(
          process.env.VUE_APP_BASE_URL + "/withdraw/current/list",
          {
            num: this.num,
            size: this.size,
          },
          {
            headers: {
              "X-CSRF-Token": localStorage.userToken,
            },
          }
        )
        .then((response) => {
          if (response.data.code == 0) {
            const result = response.data.data;
            this.result.total = result.total;
            this.result.list = this.result.list.concat(result.list);
            this.num = this.num + 1;
          } else if (response.data.code == 1) {
            alert("获取提现记录失败: " + response.data.msg);
          } else if (response.data.code == 2) {
            localStorage.clear();
            this.$router.push("/login");
          } else {
            alert("未知错误");
          }
        })
        .catch(function (error) {
          // 请求失败处理
          alert(error);
        });
    },
    goToWithdraw() {
      this.$router.push("/withdraw");
    },
  },
  computed: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.header {
  color: #ffffff;
  margin: 10px;
}

.back-btn {
  font-size: 18px;
  float: left;
  font-weight: bold;
}

.header .title {
  font-size: 16px;
}

.list-panel {
  text-align: left;
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 10px;
  overflow: scroll;
  height: calc(100vh - 60px);
}

.list-panel .item {
  margin-top: 10px;
  padding: 10px 15px;
  background-color: #333333;
  border-radius: 10px;
  position: relative;
}

.list-panel .item:first-child {
  margin-top: 0px;
}

.audit-status {
  position: absolute;
  right: 15px;
  top: 60px;
}
</style>
