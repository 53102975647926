<template>
  <div class="header">
    <div class="back-btn">
      <img
        @click="goToList"
        src="../assets/left-arrow.png"
        width="20"
        height="20"
        style="position: relative; top: 3px; left: -5px;"
      />
    </div>
    <div class="title">渠道编辑</div>
  </div>

  <div style="padding: 10px; text-align: left;">
    <div style="margin-bottom: 10px">
      <div>手机号</div>
      <div><input v-model="username" class="input" /></div>
    </div>
    <div style="margin-bottom: 10px">
      <div>姓名</div>
      <div><input v-model="name" class="input" /></div>
    </div>
    <div style="margin-bottom: 10px">
      <div>支付宝账号</div>
      <div><input v-model="alipayAccount" class="input" /></div>
    </div>
    <div style="margin-bottom: 10px">
      <div>分摊市代比例(%)</div>
      <div><input v-model="shareProportion" class="input" /></div>
    </div>
    <div style="margin-bottom: 10px">
      <div>平台分成比例(%)</div>
      <div><input v-model="tempProportion" class="input" readonly disabled /></div>
    </div>
    <div>
      <div>备注</div>
      <div><textarea v-model="remark" class="textarea" /></div>
    </div>
    <div>
      <button class="submit-button" @click="submit">提交</button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "ChannelManageEditView",
  components: {},
  data() {
    return {
      userInfo: null,
      id: null,
      username: null,
      name: null,
      alipayAccount: null,
      shareProportion: null,
      remark: null,
    };
  },
  mounted() {
    this.loadUserInfo()
    this.id = this.$route.query.id
    if(this.id) {
      this.load()
    }
  },
  methods: {
    load() {
      axios
        .post(process.env.VUE_APP_BASE_URL + "/user/channel/load/" + this.id, 
          null, 
          {
            headers: {
              "X-CSRF-Token": localStorage.userToken,
            },
          }
        )
        .then((response) => {
          if (response.data.code == 0) {
            this.username = response.data.data.username
            this.name = response.data.data.name
            this.alipayAccount = response.data.data.alipayAccount
            this.shareProportion = response.data.data.shareProportion
            this.remark = response.data.data.remark
          } else if (response.data.code == 1) {
            alert("获取渠道失败: " + response.data.msg);
          } else if (response.data.code == 2) {
            localStorage.clear();
            this.$router.push("/login");
          } else {
            alert("未知错误");
          }
        })
        .catch(function (error) {
          // 请求失败处理
          alert(error);
        });
    },
    goToList() {
      this.$router.push("/channel/list");
    },
    submit() {
      axios
        .post(process.env.VUE_APP_BASE_URL + "/user/channel/save", 
          {
            id: this.id,
            username: this.username,
            name: this.name,
            alipayAccount: this.alipayAccount,
            shareProportion: this.tempProportion,
            remark: this.remark,
          }, 
          {
            headers: {
              "X-CSRF-Token": localStorage.userToken,
            },
          }
        )
        .then((response) => {
          if (response.data.code == 0) {
            alert('保存成功')
            this.goToList()
          } else if (response.data.code == 1) {
            let err = response.data.msg ?? "\n"
            for(let i in response.data.data) {
              err += response.data.data[i] + "\n"
            }
            alert("获取渠道失败: " + err);
          } else if (response.data.code == 2) {
            localStorage.clear();
            this.$router.push("/login");
          } else {
            alert("未知错误");
          }
        })
        .catch(function (error) {
          // 请求失败处理
          alert(error);
        });
    },
    loadUserInfo() {
      axios
        .post(process.env.VUE_APP_BASE_URL + "/user/current-info", null, {
          headers: {
            "X-CSRF-Token": localStorage.userToken,
          },
        })
        .then((response) => {
          if (response.data.code == 0) {
            this.userInfo = response.data.data;
          } else if (response.data.code == 1) { 
            alert("获取用户信息失败: " + response.data.msg);
          } else if (response.data.code == 2) {
            localStorage.clear();
            this.$router.push("/login");
          } else {
            alert("未知错误");
          }
        })
        .catch(function (error) {
          // 请求失败处理
          alert(error);
        });
    },
  },
  computed: {
    tempProportion() {
      return (this.userInfo?.shareProportion ?? 0) * this.shareProportion / 100
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.header {
  color: #ffffff;
  margin: 10px;
}

.back-btn {
  font-size: 18px;
  float: left;
  font-weight: bold;
}

.header .title {
  font-size: 16px;
}

.balance-panel,
.info-panel,
.journal-panel {
  text-align: left;
}

.exit {
  position: absolute;
  right: 10px;
  top: 10px;
}

.info-panel {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.info {
  position: relative;
  top: 5px;
}

.info .title {
  margin-bottom: 5px;
}

.balance-panel {
  margin-left: 10px;
  margin-right: 10px;
}

.balance-title {
  margin-top: 5px;
  font-size: 18px;
}

.button-area {
  position: relative;
}

.withdraw-button {
  position: absolute;
  background-color: #bdff06;
  right: 0;
  top: -38px;
  padding: 5px 20px;
  border-radius: 10px;
}

.submit-button {
  background-color: #bdff06;
  padding: 10px 20px;
  border-radius: 10px;
  display: block;
  margin: 20px auto;
  width: 100%;
}

.journal-panel {
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 10px;
}

.journal-panel .title {
  color: #bdff06;
  margin-bottom: 10px;
}

.list-panel {
  overflow: scroll;
  height: calc(100vh - 320px);
}

.list-panel .item {
  margin-top: 10px;
  padding: 10px 15px;
  background-color: #333333;
  border-radius: 10px;
}

.list-panel .item:first-child {
  margin-top: 0px;
}

.input, .textarea {
  margin-top: 5px;
  width: 98%; 
  border-radius: 5px; 
  height: 20px;
  background-color: #555555;
  border: none;
  outline: none;
  color: #ffffff;
  padding: 5px;
}

.textarea {
  height: 50px;
}
</style>
