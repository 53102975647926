<template>
  <div class="header">
    <div class="back-btn">
      <img
        @click="goToIndex"
        src="../assets/left-arrow.png"
        width="20"
        height="20"
        style="position: relative; top: 3px; left: -5px;"
      />
    </div>
    <div class="title">渠道列表</div>
  </div>

  <div class="exit">
    <img height="20" style="margin-right: 5px;" title="addEl" src="../assets/add.png" @click="goToAdd" />
  </div>
  <div class="journal-panel">
    <div class="list-panel" v-if="hasResult"  @scroll="handleScroll">
      <div class="item" v-for="(item, index) in result.list" :key="index" @click="goToEdit(item.id)">
        <div>
          <div
            style="
              display: flex;
              flex-direction: row;
              margin-bottom: 5px;
              position: relative;
              color: greenyellow;
            "
          >
            <div>{{ item.createTime }}</div>
          </div>
          <div>
            <div style="margin-bottom: 5px"><span style="display:inline-block; width: 70px; text-align: left;">手机号:</span> {{ item.username }}</div>
            <div style="margin-bottom: 5px"><span style="display:inline-block; width: 70px; text-align: left;">姓名:</span> {{ item.name }}</div>
            <div style="margin-bottom: 5px"><span style="display:inline-block; width: 70px; text-align: left;">支付宝账号:</span> {{ item.alipayAccount }}</div>
            <div style="margin-bottom: 5px"><span style="display:inline-block; width: 70px; text-align: left;">平台分成:</span> {{ item.shareProportion }}%</div>
            <div style="color: #bdff06;"><span style="display:inline-block; width: 30px; text-align: left;">备注:</span> {{ item.remark }}</div>
          </div>
        </div>
      </div>
      <div v-if="result.isEnded" style="padding-top: 10px; text-align: center;">
        <span class="empty-span">已经到底啦 &gt;_&lt;</span>
      </div>
    </div>
    <div v-else style="flex: 1; text-align: center;">
      <div style="flex: 1;">
        <div style="flex: 1; padding-top: 100px;">
          <span class="empty-span">暂无内容</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "ChannelManageListView",
  components: {},
  data() {
    return {
      num: 1,
      size: 20,
      result: {
        total: 0,
        list: [],
        isEnded: false,
      },
    };
  },
  mounted() {
    this.loadList();
  },
  methods: {
    goToIndex() {
      this.$router.push("/index");
    },
    goToEdit(id) {
      this.$router.push({path:"/channel/edit",query: {id}});
    },
    goToAdd() {
      this.$router.push("/channel/edit");
    },
    loadList() {
      axios
        .post(process.env.VUE_APP_BASE_URL + "/user/channel/list", 
        {
          data: {
            key: null
          },
          page: {
            num: this.num,
            size: this.size,
          }
        }, 
        {
          headers: {
            "X-CSRF-Token": localStorage.userToken,
          },
        }
      )
        .then((response) => {
          if (response.data.code == 0) {
            this.result.list = this.result.list.concat(response.data.data.list);
            this.result.total = response.data.data.total;
            this.result.isEnded = response.data.data.total < this.num * this.size
          } else if (response.data.code == 1) {
            alert("获取渠道失败: " + response.data.msg);
          } else if (response.data.code == 2) {
            localStorage.clear();
            this.$router.push("/login");
          } else {
            alert("未知错误");
          }
        })
        .catch(function (error) {
          // 请求失败处理
          alert(error);
        });
    },
    handleScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (scrollTop + clientHeight >= scrollHeight) {
        this.num = this.num + 1
        this.loadList();
      }
    },
  },
  computed: {
    hasResult() {
      return this.result.total > 0
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.header {
  color: #ffffff;
  margin: 10px;
}

.back-btn {
  font-size: 18px;
  float: left;
  font-weight: bold;
}

.header .title {
  font-size: 16px;
}

.balance-panel,
.info-panel,
.journal-panel {
  text-align: left;
}

.exit {
  position: absolute;
  right: 10px;
  top: 10px;
}

.info-panel {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.info {
  position: relative;
  top: 5px;
}

.info .title {
  margin-bottom: 5px;
}

.balance-panel {
  margin-left: 10px;
  margin-right: 10px;
}

.balance-title {
  margin-top: 5px;
  font-size: 18px;
}

.button-area {
  position: relative;
}

.withdraw-button {
  position: absolute;
  background-color: #bdff06;
  right: 0;
  top: -38px;
  padding: 5px 20px;
  border-radius: 10px;
}

.copy-button {
  background-color: #bdff06;
  padding: 5px 20px;
  border-radius: 10px;
  display: block;
  margin: 20px auto;
}

.journal-panel {
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 10px;
}

.journal-panel .title {
  color: #bdff06;
  margin-bottom: 10px;
}

.list-panel {
  overflow: scroll;
  /* height: calc(100vh - 320px); */
}

.list-panel .item {
  margin-top: 10px;
  padding: 10px 15px;
  background-color: #333333;
  border-radius: 10px;
}

.list-panel .item:first-child {
  margin-top: 0px;
}
</style>
