<template>
  <div v-show="showQrcodeFlag">
    <div class="qrcodeMask"></div>
    <div class="qrcode">
      <img id="qrcode" title="qrcodeEl" height="250" :src="qrcodePath" />
      <!-- <div style="display: flex; flex-direction: column; justify-content: center;"> -->
      <div>
        <div class="copy-text">长按图片保存到本地</div>
        <button @click="copyRegisterUrl" class="copy-button">复制分享链接</button>
        <!-- <button @click="" class="copy-button">保存二维码</button> -->
      </div>
    </div>
  </div>

  <div v-show="showMenuFlag">
    <div class="qrcodeMask"></div>
    <div class="leftMenus">
      <div class="leftMenu" @click="openUpload">更换头像</div>
      <div class="leftMenu" v-if="userInfo.agent" @click="goToChannelManage">渠道管理</div>
      <div class="leftMenu" @click="goToMyClient">我的客户</div>
      <div class="leftMenu" @click="logout">退出</div>
    </div>
  </div>

  <div class="exit">
    <img height="40" style="margin: 10px;" title="qrcodeEl" src="../assets/qrcode.png" @click="showQrcode" />
  </div>
  <div class="info-panel">
    <div style="margin-right: 10px;">
      <img
        title="setupEl"
        @click="showMenu"
        v-if="!!userInfo.avatar"
        :src="userInfo.avatar"
        class="avatar"
      />
      <img
        title="setupEl"
        @click="showMenu"
        v-else
        src="../assets/avatar.png"
        class="avatar"
      />
      <input
        ref="fileRef"
        type="file"
        style="display: none"
        @change="uploadFile"
        accept="image/*"
      />
      <div style="text-align: center; color: #bdff06;">{{ userInfo.cooperationType?.text }}</div>
    </div>
    <div class="info">
      <div class="title" style="font-size: 14px; font-weight: bold; color: #bdff06;">{{ userInfo.name }} - {{ userInfo.username }}</div>
      <div class="title">{{ userInfo.cooperationType?.name === 'Channel' ? '所属' : '代理'}}区域：{{ userInfo.area }}</div>
      <div v-if="!!userInfo.parent" class="title">上级代理：{{ userInfo.parent }}</div>
      <div class="title">分成比例：{{ userInfo.shareProportion }}%</div>
    </div>
  </div>
  <div style="border-bottom: 1px solid #555555; margin: 0px 12px 10px;"></div>
  <div class="balance-panel">
    <div>
      <div>余额：</div>
      <div class="balance-title">
        <strong>￥{{ userInfo.balance }}</strong>
      </div>
    </div>
    <div class="button-area">
      <button @click="goToWithdraw" class="withdraw-button">提现</button>
    </div>
    <div class="summary">
      <div class="card">
        <div class="value">￥{{ userInfo.currentMonthIncome }}</div>
        <div class="title">本月收入</div>
      </div>
      <div class="card">
        <div class="value">￥{{ userInfo.totalIncome }}</div>
        <div class="title">收入共计</div>
      </div>
      <div class="card">
        <div class="value">￥{{ userInfo.totalWithdrawAmount }}</div>
        <div class="title">累计提现</div>
      </div>
    </div>
  </div>
  <div class="journal-panel">
    <div class="title">收支明细</div>
    <div class="list-panel" @scroll="handleScroll">
      <div class="item" v-for="(item, index) in result.list" :key="index">
        <div v-if="item.balanceType.name === 'ShareProfit'" style="margin-top: 5px">
          <div
            style="
              display: flex;
              flex-direction: row;
              margin-bottom: 5px;
              position: relative;
            "
          >
            <div>{{ item.createTime }}</div>
            <div
              style="
                color: orange;
                position: absolute;
                right: 0;
              "
            >
              会费分成: +￥{{ item.amount }}
            </div>
          </div>
          <div style="margin-bottom: 5px">客户: {{ item.clientName }}</div>
          <div style="margin-bottom: 5px">购买VIP服务: <span style="position: relative;">￥{{ item.vipAmount }}</span></div>
          <div style="margin-bottom: 5px" v-if="item.level1AgentName && !item.level2AgentName && !item.level3AgentName">
            <span v-if="item.direct">直接分成比例:</span>
            <span v-else>区域分成比例:</span>
            {{ item.level1Proportion }}%
          </div>
          <div style="margin-bottom: 5px" v-if="item.level2AgentName && !item.level3AgentName">
            <span v-if="item.direct">间接分成比例:</span>
            <span v-else>区域分成比例:</span>
            {{ item.level2Proportion }}% <span v-if="item.direct">({{ item.level1AgentName }})</span>
          </div>
          <div style="margin-bottom: 5px" v-if="item.level3AgentName">
            三级分成比例:
            {{ item.level3Proportion }}% ({{ item.level2AgentName }})
          </div>
        </div>
        <div v-else-if="item.balanceType.name === 'InvitationProfit'" style="margin-top: 5px">
          <div
            style="
              display: flex;
              flex-direction: row;
              margin-bottom: 5px;
              position: relative;
            "
          >
            <div>{{ item.createTime }}</div>
            <div
              style="
                float: right;
                color: orange;
                position: absolute;
                right: 0;
              "
            >
              分享奖励: +￥{{ item.amount }}
            </div>
          </div>
          <!-- <div style="margin-bottom: 5px">
            余额: {{ item.afterPaidBalance }}
          </div> -->
          <div style="margin-bottom: 5px">
            客户: {{ item.clientName }}
          </div>
        </div>
        <div v-else  style="margin-top: 5px">
          <div
            style="
              display: flex;
              flex-direction: row;
              margin-bottom: 5px;
              position: relative;
            "
          >
            <div>{{ item.createTime }}</div>
            <div
              style="
                float: right;
                color: greenyellow;
                position: absolute;
                right: 0;
              "
            >
              -{{ item.amount }}
            </div>
          </div>
          <div style="margin-bottom: 5px">
            余额: {{ item.afterPaidBalance }}
          </div>
          <div style="margin-bottom: 5px">手续费: {{ item.fee }}</div>
        </div>
      </div>
    </div>
    <div v-if="ended" style="padding-top: 10px; text-align: center;">
      <span class="empty-span">已经到底啦 &gt;_&lt;</span>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "IndexView",
  components: {},
  data() {
    return {
      qrcodePath: null,
      registerUrl: null,
      showMenuFlag: false,
      showQrcodeFlag: false,
      userInfo: {},
      ended: false,
      num: 1,
      size: 20,
      result: {
        total: 0,
        list: [],
      },
    };
  },
  mounted() {
    this.loadUserInfo();
    this.loadJournals();
    this.getQrcode();
    document.addEventListener('click', (event)=> {
      if (event.target.title !== 'setupEl') {
        this.showMenuFlag = false
      } 
      if (event.target.title !== 'qrcodeEl' && event.target.title !== 'qrcodeMenuEl') {
        this.showQrcodeFlag = false
      }
    })
  },
  methods: {
    goToChannelManage() {
      this.$router.push("/channel/list");
    },
    goToMyClient() {
      this.$router.push("/client/list");
    },
    copyRegisterUrl() {
      const copyipt = document.createElement("input");
      copyipt.setAttribute("value", this.registerUrl);
      document.body.appendChild(copyipt);
      copyipt.select();
      document.execCommand("copy");
      document.body.removeChild(copyipt);
      alert('复制成功')
    },
    showQrcode() {
      this.showQrcodeFlag = true
    },
    showMenu() {
      this.showMenuFlag = true
    },
    getQrcode() {
      axios
        .post(process.env.VUE_APP_BASE_URL + "/user/get-qrcode", null, {
          headers: {
            "X-CSRF-Token": localStorage.userToken,
          },
        })
        .then((response) => {
          if (response.data.code == 0) {
            this.qrcodePath = response.data.data.path + "?t=" + new Date().getTime()
            this.registerUrl = response.data.data.registerUrl
          } else if (response.data.code == 1) {
            alert("获取分享二维码失败: " + response.data.msg);
          } else if (response.data.code == 2) {
            localStorage.clear();
            this.$router.push("/login");
          } else {
            alert("未知错误");
          }
        })
        .catch(function (error) {
          // 请求失败处理
          alert(error);
        });
    },
    loadUserInfo() {
      axios
        .post(process.env.VUE_APP_BASE_URL + "/user/current-info", null, {
          headers: {
            "X-CSRF-Token": localStorage.userToken,
          },
        })
        .then((response) => {
          if (response.data.code == 0) {
            this.userInfo = response.data.data;
          } else if (response.data.code == 1) { 
            alert("获取用户信息失败: " + response.data.msg);
          } else if (response.data.code == 2) {
            localStorage.clear();
            this.$router.push("/login");
          } else {
            alert("未知错误");
          }
        })
        .catch(function (error) {
          // 请求失败处理
          alert(error);
        });
    },
    goToWithdraw() {
      this.$router.push("/withdraw");
    },
    uploadFile(e) {
      //获取文件
      const file = e.target.files[0];
      //将其放入formdata中方便上传
      const formData = new FormData();
      formData.append("files", file);
      axios
        .post(process.env.VUE_APP_BASE_URL + "/user/avatar/upload", formData, {
          headers: {
            "X-CSRF-Token": localStorage.userToken,
          },
        })
        .then((response) => {
          if (response.data.code == 0) {
            const result = response.data.data[0];
            this.userInfo.avatar = result.domain + "/" + result.path;
            this.getQrcode();
          } else if (response.data.code == 1) {
            alert("上传失败: " + response.data.msg);
          } else if (response.data.code == 2) {
            localStorage.clear();
            this.$router.push("/login");
          } else {
            alert("未知错误");
          }
        })
        .catch(function (error) {
          // 请求失败处理
          alert(error);
        });
    },
    openUpload() {
      this.$refs.fileRef.click();
    },
    handleScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (scrollTop + clientHeight >= scrollHeight) {
        if (!this.ended) {
          this.loadJournals();
        }
      }
    },
    logout() {
      axios
        .post(process.env.VUE_APP_BASE_URL + "/logout", null, {
          headers: {
            "X-CSRF-Token": localStorage.userToken,
          },
        })
        .then((response) => {
          if (response.data.code == 0) {
            localStorage.clear();
            this.$router.push("/login");
          } else if (response.data.code == 1) {
            alert("退出失败: " + response.data.msg);
          } else if (response.data.code == 2) {
            localStorage.clear();
            this.$router.push("/login");
          } else {
            alert("未知错误");
          }
        })
        .catch(function (error) {
          // 请求失败处理
          alert(error);
        });
    },
    loadJournals() {
      axios
        .post(
          process.env.VUE_APP_BASE_URL + "/balance-journal/current/list",
          {
            num: this.num,
            size: this.size,
          },
          {
            headers: {
              "X-CSRF-Token": localStorage.userToken,
            },
          }
        )
        .then((response) => {
          if (response.data.code == 0) {
            const result = response.data.data;
            this.result.total = result.total;
            this.result.list = this.result.list.concat(result.list);
            this.num = this.num + 1;
            this.ended = this.num * this.size >= this.total
          } else if (response.data.code == 1) {
            alert("获取收支明细失败: " + response.data.msg);
          } else if (response.data.code == 2) {
            localStorage.clear();
            this.$router.push("/login");
          } else {
            alert("未知错误");
          }
        })
        .catch(function (error) {
          // 请求失败处理
          alert(error);
        });
    },
  },
  computed: {
    qrcodeUrl() {
      return process.env.VUE_APP_BASE_URL + "/user/qrcode"
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.leftMenus {
  width: 120px;
  position: fixed;
  height: 100%;
  z-index: 1000;
  text-align: left;
  background-color: #333333;
}

.leftMenu {
  margin: 20px 10px 30px;
  font-size: 14px;
}

.qrcodeMask {
  position: absolute;
  padding: 0;
  margin: 0;
  background-color: gray;
  opacity: 0.5;
  height: 100%;
  width: 100%;
  z-index: 1000;
}

.qrcode {
  z-index: 1000;
  opacity: 1;
  position: absolute;
  top: 200px;
  width: 100%;
}

.setupMenu {
  z-index: 10;
  border-radius: 5px;
  position: absolute;
  right: 10px;
  top: 40px;
  text-align: right;
  font-size: 16px;
  background-color: #333333;
  padding: 10px;
}

.setupMenu div {
  margin-bottom: 20px;
}

.setupMenu div:last-child {
  margin-bottom: 5px;
}

.filter-green {
  filter: invert(48%) sepia(79%) saturate(0%) hue-rotate(86deg) brightness(118%)
    contrast(119%);
  width: 32px;
  height: 32px;
}

.balance-panel,
.info-panel,
.journal-panel {
  text-align: left;
}

.exit {
  position: absolute;
  right: 10px;
  top: 10px;
}

.info-panel {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.avatar {
  width: 72px;
  height: 72px;
  border-radius: 30px;
  /* margin-right: 10px; */
}
.info {
  position: relative;
  top: 5px;
}

.info .title {
  margin-bottom: 5px;
}

.balance-panel {
  margin-left: 10px;
  margin-right: 10px;
}

.balance-title {
  margin-top: 5px;
  font-size: 18px;
}

.button-area {
  position: relative;
}

.withdraw-button {
  position: absolute;
  background-color: #bdff06;
  right: 0;
  top: -38px;
  padding: 5px 20px;
  border-radius: 10px;
}

.copy-button {
  background-color: #bdff06;
  padding: 15px 80px;
  border-radius: 10px;
  display: block;
  font-size: 16px;
  margin: 10px auto;
}

.copy-text {
  font-size: 16px;
}

.summary {
  display: flex;
  flex: 1;
  margin-top: 20px;
  background-color: #333333;
  padding: 20px 0px;
  border-radius: 10px;
}

.summary .card {
  margin: auto;
  text-align: center;
}

.summary .card .title {
  color: #ffffff;
  margin-top: 10px;
}

.summary .card .value {
  color: #bdff06;
  font-size: 16px;
}

.journal-panel {
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 10px;
}

.journal-panel .title {
  color: #bdff06;
  margin-bottom: 10px;
}

.list-panel {
  overflow: scroll;
  height: calc(100vh - 320px);
}

.list-panel .item {
  margin-top: 10px;
  padding: 10px 15px;
  background-color: #333333;
  border-radius: 10px;
}

.list-panel .item:first-child {
  margin-top: 0px;
}
</style>
