<template>
  <div class="header">
    <div class="back-btn">
      <img
        @click="goToIndex"
        src="../assets/left-arrow.png"
        width="20"
        height="20"
        style="position: relative; top: 3px"
      />
    </div>
    <div class="title">提现</div>
  </div>
  <div style="margin: 0 10px">
    <div class="input-area">
      <div>
        提现金额:
        <span style="color: darkgray"
          >(当前可提余额: ￥{{ balanceInfo.balance ?? 0 }})</span
        >
      </div>
      <div style="margin-top: 5px; position: relative;">
        <span class="dollar-title">￥: </span
        ><input
          type="text"
          placeholder="请输入提现金额"
          class="input"
          v-model="amount"
          @input="inputAmount"
        />
        <span style="position: absolute; right: 0; top: 3px; color: darkgray"
          >最低提现金额￥{{ balanceInfo.minAmount ?? 10 }}</span
        >
      </div>
    </div>
    <div class="payment-area">
      <div>选择提现方式</div>
      <div
        style="margin: 10px 0px 0px 0px; font-size: 16px; position: relative"
      >
        <img src="../assets/alipay.png" width="28" height="28" />
        <span style="position: relative; top: -8px">支付宝</span>
        <input
          type="radio"
          name="paymentType"
          class="payment-input"
          value="Alipay"
          @click="inputAlipay"
        />
      </div>
      <div style="color: darkgray">
        支付宝账号: {{ balanceInfo.alipayAccount ?? "尚未设置" }}
      </div>
      <div style="margin-top: 20px; text-align: center">
        <button @click="withdraw" class="withdraw-button">
          <img src="../assets/withdraw2.png" width="20" height="20" /><span
            >提现</span
          >
        </button>
      </div>
      <div
        style="
          margin-top: 20px;
          text-align: center;
          font-size: 14px;
          text-decoration: underline;
        "
      >
        <span @click="goToWithdrawJournal">提现记录</span>
      </div>
    </div>
    <div style="text-align: left; margin-top: 10px">
      <div style="color: #bdff06; margin-bottom: 5px">注意事项:</div>
      <div style="margin-bottom: 5px">
        1. 提现收取{{ balanceInfo.feeRatio }}%的手续费;
      </div>
      <div style="margin-bottom: 5px">2. 提现24小时内到账;</div>
      <div>3. 提现审核失败，会自动退回到余额;</div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "WithdrawView",
  components: {},
  data() {
    return {
      balanceInfo: {},
      amount: null,
      paymentType: null,
    };
  },
  mounted() {
    this.loadInfo();
  },
  methods: {
    goToWithdrawJournal() {
      this.$router.push("/withdrawJournal");
    },
    inputAlipay() {
      this.paymentType = "AliPay";
    },
    withdraw() {
      if (this.amount == null) {
        alert("请输入提现金额");
        return;
      }
      if (this.amount < this.balanceInfo.minAmount) {
        alert("提现金额不能低于" + this.balanceInfo.minAmount);
        return;
      }
      if (this.paymentType == null) {
        alert("请选择提现方式");
        return;
      }
      axios
        .post(
          process.env.VUE_APP_BASE_URL + "/withdraw/save",
          {
            amount: this.amount,
            paymentType: this.paymentType,
          },
          {
            headers: {
              "X-CSRF-Token": localStorage.userToken,
            },
          }
        )
        .then((response) => {
          if (response.data.code == 0) {
            // this.amount = null;
            // this.paymentType = null;
            alert("提现申请成功，请等待审核结果");
            this.goToWithdrawJournal();
          } else if (response.data.code == 1) {
            alert("提现申请失败: " + response.data.msg);
          } else if (response.data.code == 2) {
            localStorage.clear();
            this.$router.push("/login");
          } else {
            alert("未知错误");
          }
        })
        .catch(function (error) {
          // 请求失败处理
          alert(error);
        });
    },
    inputAmount(event) {
      const value = parseInt(event.target.value);
      const valid = !isNaN(value);
      if (valid) {
        this.amount = value;
      } else {
        this.amount = null;
      }
    },
    loadInfo() {
      axios
        .post(process.env.VUE_APP_BASE_URL + "/withdraw/info", null, {
          headers: {
            "X-CSRF-Token": localStorage.userToken,
          },
        })
        .then((response) => {
          if (response.data.code == 0) {
            this.balanceInfo = response.data.data;
          } else if (response.data.code == 1) {
            alert("获取余额失败: " + response.data.msg);
          } else if (response.data.code == 2) {
            localStorage.clear();
            this.$router.push("/login");
          } else {
            alert("未知错误");
          }
        })
        .catch(function (error) {
          // 请求失败处理
          alert(error);
        });
    },
    goToIndex() {
      this.$router.push("/index");
    },
  },
  computed: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.header {
  color: #ffffff;
  margin: 10px;
}

.back-btn {
  font-size: 18px;
  float: left;
  font-weight: bold;
}

.header .title {
  font-size: 16px;
}

.input-area,
.payment-area {
  text-align: left;
  color: #ffffff;
  margin-top: 30px;
  padding: 10px 15px;
  background-color: #333333;
  border-radius: 10px;
}

.dollar-title {
  color: orange;
  font-size: 16px;
  font-weight: bold;
  position: relative;
  top: 1px;
}

.input {
  border: none;
  outline: none;
  background-color: #333333;
  color: #ffffff;
  font-size: 14px;
}

.payment-input {
  position: absolute;
  right: -6px;
  top: 2px;
  width: 16px;
  height: 16px;
}

.withdraw-button {
  background-color: #bdff06;
  padding: 10px 20px;
  border-radius: 10px;
  width: 100%;
}

.withdraw-button span {
  position: relative;
  top: -4px;
  left: 5px;
}
</style>
