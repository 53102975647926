import { createRouter, createWebHashHistory } from 'vue-router'
import LoginView from '../views/LoginView.vue'
import IndexView from '../views/IndexView.vue'
import WithdrawView from '../views/WithdrawView.vue'
import WithdrawJournalView from '../views/WithdrawJournalView.vue'
import ChannelManageListView from '../views/ChannelManageListView.vue'
import ChannelManageEditView from '../views/ChannelManageEditView.vue'
import MyClientListView from '../views/MyClientListView.vue'

const routes = [
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/index',
    name: 'index',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: IndexView
  },
  {
    path: '/withdraw',
    name: 'withdraw',
    component: WithdrawView
  },
  {
    path: '/withdrawJournal',
    name: 'withdrawJournal',
    component: WithdrawJournalView
  },
  {
    path: '/client/list',
    name: 'MyClientList',
    component: MyClientListView
  },
  {
    path: '/channel/list',
    name: 'channelManageList',
    component: ChannelManageListView
  },
  {
    path: '/channel/edit',
    name: 'channelManageEdit',
    component: ChannelManageEditView
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
