<template>
  <div class="header">
    <div class="back-btn">
      <img
        @click="goToIndex"
        src="../assets/left-arrow.png"
        width="20"
        height="20"
        style="position: relative; top: 3px; left: -5px;"
      />
    </div>
    <div class="title">我的客户</div>
  </div>

  <div class="search-div">
    <div class="input-wrapper">
      <input class="uni-input" v-model="key" placeholder="请输入客户名称" />
      <img class="uni-icon" src="../assets/clear.png" @click="clearKey" v-if="showClearIcon" />
    </div>
    <img @click="search" src="../assets/search.png" class="search-icon" />
	</div>
	<div class="total-line">
		<span class="uni-span" style="color: #bdff06;">您已经邀请了 <span style="color: #ffffff;">{{allMembers}}</span> 个商家注册</span>
	</div>
	<div class="list-panel" v-if="hasResult"  @scroll="handleScroll">
		<div v-for="(item,index) in result.list" :key="index">
			<div class="row">
				<img class="avatar" v-if="hasAvatar(item.avatar)" :src="item.avatar" />
				<img class="avatar" v-else src="../assets/avatar.png" />
				<div style="text-align: left; position: relative; left: 20px;">
					<div style="margin-bottom: 5px;">{{item.nickname}} - {{ item.username }}</div>
					<div style="margin-bottom: 5px;">注册时间: {{item.createTime}}</div>
          <div>累计缴费: <span style="color: orange;">￥{{ item.totalPaid }}</span></div>
				</div>
			</div>
		</div>
    <div v-if="result.isEnded" style="padding-top: 10px; text-align: center;">
      <span class="empty-span">已经到底啦 &gt;_&lt;</span>
    </div>
	</div>
	<div v-else style="flex: 1;">
		<div style="flex: 1;">
			<div style="flex: 1; padding-top: 100px;">
				<span class="empty-span">暂无内容</span>
			</div>
		</div>
	</div>
</template>

<script>
import axios from "axios";
export default {
  name: "ChannelManageListdiv",
  components: {},
  data() {
    return {
      key: "",
      num: 1,
      size: 20,
      result: {
        total: 0,
        list: [],
        isEnded: false,
      },
      allMembers: 0
    };
  },
  mounted() {
    this.loadList();
  },
  methods: {
    clearKey() {
      this.key = ""
    },
    search() {
      this.result.list = []
      this.result.total = 0
      this.result.isEnded = false
      this.loadList()
    },
    goToIndex() {
      this.$router.push("/index");
    },
    goToEdit(id) {
      this.$router.push({path:"/channel/edit",query: {id}});
    },
    goToAdd() {
      this.$router.push("/channel/edit");
    },
    hasAvatar(avatar) {
      return avatar !== undefined && avatar !== null && avatar.trim() !== ""
    },
    loadList() {
      axios
        .post(process.env.VUE_APP_BASE_URL + "/user/client/list", 
        {
          data: {
            key: this.key
          },
          page: {
            num: this.num,
            size: this.size,
          }
        }, 
        {
          headers: {
            "X-CSRF-Token": localStorage.userToken,
          },
        }
      )
        .then((response) => {
          if (response.data.code == 0) {
            this.result.list = this.result.list.concat(response.data.data.list);
            this.result.total = response.data.data.total;
            this.result.isEnded = response.data.data.total < this.num * this.size
            if (response.data.data.total > 0 && this.allMembers === 0) {
              this.allMembers = response.data.data.total
            }
          } else if (response.data.code == 1) {
            alert("获取客户失败: " + response.data.msg);
          } else if (response.data.code == 2) {
            localStorage.clear();
            this.$router.push("/login");
          } else {
            alert("未知错误");
          }
        })
        .catch(function (error) {
          // 请求失败处理
          alert(error);
        });
    },
    handleScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (scrollTop + clientHeight >= scrollHeight) {
        this.loadList();
      }
    },
  },
  computed: {
    hasResult() {
      return this.result.total > 0
    },
    showClearIcon() {
      return this.key.length > 0
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.header {
  color: #ffffff;
  margin: 10px;
}

.back-btn {
  font-size: 18px;
  float: left;
  font-weight: bold;
}

.header .title {
  font-size: 16px;
}

.balance-panel,
.info-panel,
.journal-panel {
  span-align: left;
}

.exit {
  position: absolute;
  right: 10px;
  top: 10px;
}

.info-panel {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.info {
  position: relative;
  top: 5px;
}

.info .title {
  margin-bottom: 5px;
}

.balance-panel {
  margin-left: 10px;
  margin-right: 10px;
}

.balance-title {
  margin-top: 5px;
  font-size: 18px;
}

.button-area {
  position: relative;
}

.withdraw-button {
  position: absolute;
  background-color: #bdff06;
  right: 0;
  top: -38px;
  padding: 5px 20px;
  border-radius: 10px;
}

.copy-button {
  background-color: #bdff06;
  padding: 5px 20px;
  border-radius: 10px;
  display: block;
  margin: 20px auto;
}

.journal-panel {
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 10px;
}

.journal-panel .title {
  color: #bdff06;
  margin-bottom: 10px;
}

.list-panel {
  overflow: scroll;
  /* height: calc(100vh - 320px); */
}

.list-panel .item {
  margin-top: 10px;
  padding: 10px 15px;
  background-color: #333333;
  border-radius: 10px;
}

.list-panel .item:first-child {
  margin-top: 0px;
}

.search-div {
  margin-bottom: 10px;
  margin-top: 20px;
  position: relative;
}

.total-line {
		border-bottom: 1px solid #555555;
		padding: 5px;
		margin-bottom: 20px;
    text-align: left;
	}

	.row {
		flex-direction: row;
		background-color: #333333;
		padding: 10px;
		margin: 0 15px 10px;
		border-radius: 10px;
	}

	.avatar {
		width: 60px;
		height: 60px;
		border-radius: 20px;
    float: left;
	}

	.empty-text {
		color: #999999;
		font-size: 16px;
		font-weight: bold;
		text-align: center;
	}

	.end-text {
		color: #999999;
		font-size: 12px;
		text-align: center;
		margin-top: 20px;
		margin-bottom: 10px;
	}

	.search-view {
		flex-direction: row;
		margin: 20px 20px 10px 10px;
	}

	.search-icon-area {
		padding: 0 5px 0 5px;
		height: 44px;
		position: relative;
		top: 5px;
		border-top-right-radius: 20px;
		border-bottom-right-radius: 20px;
	}

	.search-icon {
		position: absolute;
    top: 12px;
    right: 10px;
		width: 24px;
		height: 24px;
    z-index: 10;
	}

	.search-input {
		flex: 1;
		border-top-left-radius: 20px;
		border-bottom-left-radius: 20px;
		margin-left: 10px;
    background-color: #555555;
	}

	.text1 {
		color: #ffffff;
		font-size: 12px;
		margin: 4px 5px;
	}

	.text2 {
		color: #999999;
		font-size: 12px;
		margin-left: 5px;
	}

  .input-wrapper {
		flex-direction: row;
    display: flex;
		flex-wrap: nowrap;
    position: relative;
	}

	.uni-input {
		height: 28px;
		font-size: 15px;
		padding: 10px;
		width: 100%;
		background-color: #333333;
		color: #ffffff;
		font-size: 12px;
    outline: none;
    border: none;
    border-radius: 10px;
	}

	.uni-icon {
		width: 20px;
		height: 20px;
    position: absolute;
    right: 40px;
    top: 14px;
	}

</style>
